import React from 'react';

// LI, FB, IG, TW
const SOCIAL_MEDIA_LINKS = [
  {
    label: 'LinkedIn',
    src: 'https://subsites.icapps.be/icapps/li-logo',
    url: 'https://www.linkedin.com/company/icapps',
  },
  {
    label: 'Facebook',
    src: 'https://subsites.icapps.be/icapps/fb-logo',
    url: 'https://www.facebook.com/icappsbe/',
  },
  {
    label: 'Instagram',
    src: 'https://subsites.icapps.be/icapps/ig-logo',
    url: 'https://www.instagram.com/icapps/?hl=en',
  },
  {
    label: 'twitter',
    src: 'https://subsites.icapps.be/icapps/tw-logo',
    url: 'https://twitter.com/icapps?lang=en',
  },
];

const EmailTemplate = ({ name, jobFunction, phoneNumber, email, color, templateRef }) => (
  <table cellPadding="0" cellSpacing="0" style={{ minWidth: '200px', border: 'none' }} ref={templateRef}>
    <tbody>
      <tr>
        <td>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td>
                  <p
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                      fontFamily: 'Helvetica, sans-serif',
                    }}
                  >
                    Met vriendelijke groeten, kind regards,
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding="0"
            cellSpacing="0"
            style={{
              fontFamily: 'Helvetica, sans-serif',
              marginTop: '16px',
            }}
          >
            <tbody>
              <tr>
                <td colSpan="1" style={{ paddingRight: '16px' }}>
                  <img
                    alt=""
                    style={{
                      verticalAlign: 'middle',
                      width: '34px',
                      height: '57px',
                      objectFit: 'contain',
                    }}
                    src={`https://signature.icapps.com/slash-${color}.png`}
                  />
                </td>
                <td colSpan="2" style={{ paddingRight: '32px' }}>
                  {name && (
                    <h1
                      style={{
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                        fontFamily: 'Helvetica, sans-serif',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#002548',
                        margin: '0 0 2px 0',
                      }}
                    >
                      {name}
                    </h1>
                  )}
                  {jobFunction && (
                    <h2
                      style={{
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                        fontFamily: 'Helvetica, sans-serif',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#C3C9D3',
                        margin: 0,
                      }}
                    >
                      {jobFunction}
                    </h2>
                  )}
                  {(email || phoneNumber) && (
                    <span
                      style={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                        margin: '7px 0 0 0',
                        fontSize: '11px',
                        color: '#009ac7',
                        textDecoration: 'none',
                      }}
                    >
                      {email && (
                        <a
                          style={{
                            display: 'inline',
                            whiteSpace: 'nowrap',
                            wordBreak: 'keep-all',
                            margin: '0 0.4rem 0 0',
                            fontSize: '11px',
                            color: '#009ac7',
                            textDecoration: 'none',
                          }}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </a>
                      )}
                      |
                      {phoneNumber && (
                        <a
                          style={{
                            display: 'inline',
                            whiteSpace: 'nowrap',
                            wordBreak: 'keep-all',
                            margin: '0 0 0 0.4rem',
                            fontSize: '11px',
                            color: '#009ac7',
                            textDecoration: 'none',
                          }}
                          href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                        >
                          {phoneNumber}
                        </a>
                      )}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="3" style={{ paddingTop: '32px' }}>
                  <a href="https://icapps.com">
                    <img
                      alt="icapps"
                      style={{
                        verticalAlign: 'middle',
                        width: '88px',
                        height: '13px',
                        objectFit: 'contain',
                      }}
                      src="https://signature.icapps.com/icapps-logo.png"
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <p
                    style={{
                      paddingTop: '8px',
                      fontFamily: 'Helvetica, sans-serif',
                      fontSize: '11px',
                      margin: 0,
                      color: '#C3C9D3',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap',
                      wordBreak: 'keep-all',
                    }}
                  >
                    <a
                      style={{
                        color: '#009ac7',
                        textDecoration: 'none',
                        paddingRight: '8px',
                      }}
                      href="https://icapps.com"
                    >
                      www.icapps.com
                    </a>
                    |
                    <a
                      style={{
                        paddingLeft: '8px',
                        fontFamily: 'Helvetica, sans-serif',
                        fontSize: '11px',
                        margin: 0,
                        color: '#C3C9D3',
                        textDecoration: 'none',
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                      }}
                      href="https://www.google.com/maps/place/iCapps/@51.2304913,4.399606,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3f6f21ac98093:0x31dea265bbfa747c!8m2!3d51.230488!4d4.4018"
                    >
                      Hangar 26/27 - Rijnkaai 100 B16 - 2000 Antwerpen
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {SOCIAL_MEDIA_LINKS.map(social => (
                    <a style={{ marginRight: '10px' }} href={social.url} key={social.label}>
                      <img
                        style={{ width: '20px', backgroundColor: 'transparent' }}
                        src={social.src}
                        alt={social.label}
                      />
                    </a>
                  ))}
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  
                    <a style={{ marginRight: '10px' }} href="https://linkedin.com/company/icapps">
                      <img
                        style={{ backgroundColor: 'transparent' }}
                        src="https://signature.icapps.com/linkedin.gif"
                        alt="Follow us on LinkedIn"
                      />
                    </a>
        
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

export default EmailTemplate;
